﻿import $ from "jquery";
import axios, { AxiosError } from "axios";
import Multilang from "../utils/multilang";
import ActionUrls from "../utils/action-urls";

export default class Account {
    static iframeElement: HTMLIFrameElement | null;
    static spinnerElement: HTMLElement | null;
    static languageCode: string = "";

    static init() {
        if ($(".js-account").length === 0) {
            return;
        }

        $(".js-account--transfer-type").on("change", Account.handleTransferSubTypeChange);
        Account.handleTransferSubTypeChange(null);
    }

    private static handleTransferSubTypeChange(e)
    {
        if ($(".js-account--transfer-type").val() == -1)
        {
            $(".js-account--lastusedshippingaddress").prop("disabled", false);
        }
        else
        {
            $(".js-account--lastusedshippingaddress").prop("disabled", true);
        }
    }
}
