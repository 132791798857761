import $ from "jquery";
import axios from "axios";
import Multilang from "../utils/multilang";

interface CustomerServicePopupData {
    question: string;
    ecid: string;
    epid: string;
};

interface CustomerServicePopupResponse {
    success: boolean;
};

export default class CustomerServicePopup {

    static popupSelector: string = ".js-customer-service-popup";
    static buttonSelector: string = ".js-customer-service-popup__button-wrapper button";
    static bodySelector: string = ".js-customer-service-popup__body";
    static submitButtonSelector: string = "#customer-service-popup__submit-button";
    static textareaSelector: string = "#customer-service-popup__textarea";
    static ecidSelector: string = "input[name='ecid']";
    static epidSelector: string = "input[name='epid']";
    static popupVisible: boolean = false;

    static showPopup() {
        $(CustomerServicePopup.popupSelector).removeClass("closed");
        $(CustomerServicePopup.popupSelector).addClass("open");
        setTimeout(() => { $(CustomerServicePopup.textareaSelector).trigger("focus"); }, 1000);
        CustomerServicePopup.popupVisible = true;
    }

    static hidePopup() {
        $(CustomerServicePopup.popupSelector).removeClass("open");
        $(CustomerServicePopup.popupSelector).addClass("closed");

        CustomerServicePopup.popupVisible = false;
    }

    static init() {
        if ($(CustomerServicePopup.buttonSelector).length === 0) {
            return;
        }

        Multilang.load();

        // console.log("CustomerServicePopup.init()");
        $(CustomerServicePopup.buttonSelector).on("click", e => {
            if (CustomerServicePopup.popupVisible) {
                CustomerServicePopup.hidePopup();
            } else {
                CustomerServicePopup.showPopup();
            }
        });

        $(CustomerServicePopup.submitButtonSelector).on("click", CustomerServicePopup.handleSubmit);
        $(CustomerServicePopup.textareaSelector).on("change keyup", CustomerServicePopup.handleTextareaChange);

        setTimeout(() => {
            $(CustomerServicePopup.popupSelector).removeClass("d-none");
            $(CustomerServicePopup.popupSelector).addClass("d-flex");
        }, 2000);
    }

    static handleSubmit(e) {
        if (CustomerServicePopup.popupVisible === false) {
            return;
        }

        var question = $(CustomerServicePopup.textareaSelector).val() as string;
        var ecid= $(CustomerServicePopup.ecidSelector).val() as string;
        var epid = $(CustomerServicePopup.epidSelector).val() as string;

        var $button = $(CustomerServicePopup.submitButtonSelector).closest("form");
        var submitUrl = $button.attr("action") as string;
        var postData: CustomerServicePopupData = { question, ecid, epid };

        axios
            .post<CustomerServicePopupResponse>(submitUrl, postData)
            .then((res) => res.data)
            .then((data: CustomerServicePopupResponse) => {
                console.log(data);
                if (data.success) {
                    toastr.success(Multilang.getTranslation("customer.service.popup.toast.success", "Uw vraag werd doorgestuurd."));
                }
                else {
                    toastr.error(Multilang.getTranslation("customer.service.popup.toast.error", "Uw vraag kan momenteel niet verstuurd worden. Probeer later opnieuw."));
                }

                CustomerServicePopup.hidePopup();
                $(CustomerServicePopup.textareaSelector).val("");
                $(CustomerServicePopup.submitButtonSelector).prop("disabled", true);
            })
            .catch((err) => console.log(err));
    }

    static handleTextareaChange(e) {
        var question = $(CustomerServicePopup.textareaSelector).val() as string;
        var disable = question?.length < 2 ?? true;
        $(CustomerServicePopup.submitButtonSelector).prop("disabled", disable);
    }
}
